@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add new animations */
@keyframes fade-in {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes slide-in {
  from { transform: translateX(-100%); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes slide-in-right {
  from { transform: translateX(100%); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

@keyframes diagonal-slide {
  from { 
    opacity: 0;
    transform: translate(-50px, -20px);
  }
  to { 
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes scale-in {
  from { transform: scale(0.8); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
}

@keyframes pulse-slow {
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
}

/* Scroll-triggered animation classes */
.scroll-fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.6s ease-out;
}

.scroll-fade-in.visible {
  opacity: 1;
  transform: translateY(0);
}

.scroll-slide-in {
  opacity: 0;
  transform: translateX(-50px);
  transition: all 0.6s ease-out;
}

.scroll-slide-in.visible {
  opacity: 1;
  transform: translateX(0);
}

.scroll-scale-in {
  opacity: 0;
  transform: scale(0.9);
  transition: all 0.6s ease-out;
}

.scroll-scale-in.visible {
  opacity: 1;
  transform: scale(1);
}

/* Stagger delays for lists */
.stagger-animation > *:nth-child(1) { transition-delay: 0s; }
.stagger-animation > *:nth-child(2) { transition-delay: 0.1s; }
.stagger-animation > *:nth-child(3) { transition-delay: 0.2s; }
.stagger-animation > *:nth-child(4) { transition-delay: 0.3s; }
.stagger-animation > *:nth-child(5) { transition-delay: 0.4s; }
.stagger-animation > *:nth-child(6) { transition-delay: 0.5s; }

.animate-fade-in {
  animation: fade-in 0.6s ease-out forwards;
}

.animate-slide-in {
  animation: slide-in 0.8s ease-out forwards;
}

.animate-pulse-slow {
  animation: pulse-slow 3s infinite;
}

.delay-100 {
  animation-delay: 0.1s;
}

.delay-200 {
  animation-delay: 0.2s;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
 
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
 
    --radius: 0.5rem;

    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
 
  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
 
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
 
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
 
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
 
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
 
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
 
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;

    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 0 0% 98%;
    --sidebar-primary-foreground: 240 5.9% 10%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rdp {
  --rdp-cell-size: 40px;
  --rdp-accent-color: #0000ff;
  --rdp-background-color: #f3f4f6;
  margin: 0;
}

.dark .rdp {
  --rdp-accent-color: rgb(37 99 235);
  --rdp-background-color: rgb(31 41 55);
}

/* Weekday header styles (Light mode) */
.calendar-wrapper .rdp-weekday {
  color: #374151; /* Dark gray for light mode */
  font-weight: 600; /* Bold for visibility */
}

/* Weekday header styles (Dark mode) */
.dark .calendar-wrapper .rdp-weekday {
  color: #9ca3af; /* Medium gray for dark mode */
  font-weight: 600; /* Same font weight */
}

/* Caption text (Month/Year) */
.calendar-wrapper .rdp-caption_label {
  color: #374151; /* Light mode */
  font-weight: 600; /* Semi-bold for visibility */
}

.dark .calendar-wrapper .rdp-caption_label {
  color: #e5e7eb; /* Dark mode */
  font-weight: 600;
}

/* Default day numbers (Light mode) */
.calendar-wrapper .rdp-day {
  color: #374151; /* Dark gray for light mode */
  font-weight: 500; /* Slightly bold for readability */
}

/* Hover effect for days in Light mode */
.calendar-wrapper .rdp-day_button:hover {
  background-color: #e0f2fe; /* Light blue */
  color: #2563eb; /* Blue text */
}

/* Default day numbers (Dark mode) */
.dark .calendar-wrapper .rdp-day {
  color: #9ca3af; /* Medium gray for dark mode */
  font-weight: 500; /* Same font weight */
}

/* Hover effect for days in Dark mode */
.dark .calendar-wrapper .rdp-day_button:hover {
  background-color: #1e3a8a; /* Deep blue */
  color: #93c5fd; /* Light blue text */
}

/* Selected day styles (Applies to numbers inside blue background) */
.calendar-wrapper .rdp-day_selected {
  background-color: var(--rdp-accent-color) !important;
  color: white !important;
  outline: none !important;
  border: none !important;
}

/* Selected range styles for start and end days */
.calendar-wrapper .rdp-day_range_start,
.calendar-wrapper .rdp-day_range_end {
  background-color: var(--rdp-accent-color) !important;
  color: white !important;
  border-radius: 100%;
  outline: none !important;
  border: none !important;
}

/* Selected range middle */
.calendar-wrapper .rdp-day_range_middle {
  background-color: var(--rdp-accent-color) !important;
  color: white !important;
  outline: none !important;
  border: none !important;
}

/* Dark mode selected range */
.dark .calendar-wrapper .rdp-day_range_middle,
.dark .calendar-wrapper .rdp-day_range_start,
.dark .calendar-wrapper .rdp-day_range_end {
  background-color: var(--rdp-accent-color) !important;
  color: white !important;
  border-radius: 100%;
  outline: none !important;
  border: none !important;
}

.rdp-months {
  justify-content: center;
  margin: 0;
}

.rdp-month {
  background-color: transparent;
}

.rdp-caption_label {
  padding: 1rem;
}

.rdp-day_today {
  font-weight: bold;
  color: var(--rdp-accent-color);
}

.calendar-container {
  position: absolute;
  right: 0;
  z-index: 50;
  width: fit-content;
  min-width: 320px;
  background-color: var(--background);
  border: 1px solid var(--border);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.calendar-wrapper .rdp-caption {
  padding: 0 0.5rem;
}

.calendar-wrapper .rdp-caption_label {
  font-weight: 600;
}

.hero-text > span {
  opacity: 0;
  animation: diagonal-slide 0.8s ease-out forwards;
}

.hero-text > span:nth-child(1) {
  animation-delay: 0s;
}

.hero-text > span:nth-child(2) {
  animation-delay: 0.2s;
}

.hero-text > span:nth-child(3) {
  animation-delay: 0.4s;
}

.hero-description {
  opacity: 0;
  animation: diagonal-slide 0.8s ease-out forwards;
  animation-delay: 0.6s;
}
